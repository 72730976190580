import {Grid, Image} from "@mantine/core";
import LoginForm from "../../components/LoginForm";
import React from "react";


function Login() {
    return (
        <Grid h={'calc(100vh - 9px)'} m={'0'}>
            <Grid.Col md={6} lg={5} p={'0'}>
                <LoginForm />
            </Grid.Col>
            <Grid.Col sx={{'& *': {height: '100%'}, '@media (max-width:800px)': {display: 'none'}}} p={'0'} sm={0} md={6} lg={7}>
                <Image height={"100%"} fit="cover" src={"/assets/login-image.jpg"} />
            </Grid.Col>
        </Grid>
    )
}

export default Login;