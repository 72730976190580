import {Button, Flex, Group, Image, Text, TextInput} from "@mantine/core";
import React from "react";
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import {loginRequest} from "../configuration/authConfig";
import {Navigate} from "react-router-dom";


function LoginForm() {

    const { instance } = useMsal();

    const handleLogin = async (loginType: String) => {
        if (loginType === "redirect") {
            await instance.loginRedirect(loginRequest)
        }
    }

    const authenticated = useIsAuthenticated()

    console.log(authenticated)

    if (authenticated) {
        return (
            <Navigate to={"/uploadImages"} />
        )
    } else {
        return (
            <Flex h={"100%"} justify={"center"} align={"center"}>
                <Flex w={"80%"} direction={"column"}>
                    <Image mb="xl" src={"/assets/Logo Outletstocks.png"}/>
                    <form>
                        <TextInput
                            mt="md"
                            mb="md"
                            label="Indirizzo Email"
                            placeholder="user@outletstocks.it"
                            radius={"lg"}
                        />
                        <TextInput
                            mt="md"
                            mb="xl"
                            label="Password"
                            radius={"lg"}
                        />
                        <Group grow>
                            <Button
                                sx={{'backgroundColor': '#aed581', 'color': 'black'}}
                                radius='lg'>LOGIN</Button>
                            <Button variant="default" radius='lg' onClick={() => handleLogin("redirect")}>
                                <Flex justify={"space-between"}>
                                    <Image src={"/assets/icon-microsoft-signin.svg"} pr={'4px'}/>
                                    <>
                                        <Text sx={{'@media (max-width: 768px)': {display: 'none'}}}>ACCEDI CON MICROSOFT</Text>
                                    </>
                                </Flex>
                            </Button>
                        </Group>
                    </form>
                </Flex>
            </Flex>

        )
    }

}

export default LoginForm