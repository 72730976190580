import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {MantineProvider} from '@mantine/core';
import {Provider} from "react-redux";
import {store} from "./redux/Store";
import {MsalProvider} from "@azure/msal-react";
import {msalConfig} from "./configuration/authConfig";
import {PublicClientApplication} from "@azure/msal-browser";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const msalInstance = new PublicClientApplication(msalConfig);

root.render(
    <Provider store={store}>
        <MsalProvider instance={msalInstance}>
                <MantineProvider>
                        <App />
                </MantineProvider>
        </MsalProvider>
    </Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
