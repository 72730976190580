import {Flex, Navbar} from "@mantine/core";
import React from "react";
import {IconCamera, IconFileSpreadsheet} from '@tabler/icons-react';
import NavBarLink from "./NavBarLink";
import NavBarUserProfile from "./NavBarUserProfile";
import {useNavigate} from "react-router";
import {useSelector} from "react-redux";
import {Store} from "../redux/Store";


function NavBarComponent() {
    const opened = useSelector((state: Store) => state.mobileNavBar)
    const navigate = useNavigate();
    return (
        <Navbar p="md" hiddenBreakpoint="sm" hidden={!opened} width={{ sm: 200, lg: 300 }}>
            <Flex direction={'column'} justify={'space-between'} style={{height: '100%'}}>
                <Navbar.Section>
                    <NavBarLink icon={<IconCamera color="black" />} color={"aed581"} label={"Caricamento Immagini"} url={"/uploadImages"} />
                    <NavBarLink icon={<IconFileSpreadsheet color="black" />} color={"aed581"} label={"Caricamento CSV"} url={"/uploadCsv"} />
                    <NavBarLink icon={<IconFileSpreadsheet color="black" />} color={"aed581"} label={"Inserimento Dati"} url={"/insertData"} />
                </Navbar.Section >
                <Navbar.Section>
                    <NavBarUserProfile />
                </Navbar.Section>
            </Flex>

        </Navbar>
    )

}

export default NavBarComponent;