import {LoadingState} from "./LoadingState";


export interface ImageRow {
    name: string,
    status: boolean,
    messages: string[]
    loading?: LoadingState
}

export const ImageColumns = [
    {accessor: 'name', title: 'Nome', width: '50%', sortable: true},
    {accessor: 'status', title: 'Stato', width: '10%', sortable: true},
    {accessor: 'messages', title: "Messaggio"},
    {accessor: 'loading', title: '', width: '5%'}
]

export const MockRecords: ImageRow[] = [
    {name: 'A', status: false, messages: ['C']},
    {name: 'D', status: false, messages: ['F']},
    {name: 'G', status: true, messages: ['I']},
]