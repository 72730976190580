import {Configuration} from "@azure/msal-browser";

export const msalConfig: Configuration = {
    auth: {
        clientId: "fca31688-8f50-4965-9634-13e2c81c0be5",
        authority: "https://login.microsoftonline.com/a3470923-b36f-4bec-9dbd-3fbd76e7aab7", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        redirectUri: process.env.REACT_APP_URL
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["openid"],
    forceRefresh: false
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};

export const getToken = ():string => {

    for (var i:number = 0; i < localStorage.length; i++){
        var value:string | null = localStorage.key(i)
        if (value != null && value.includes("idtoken")) {
            var obj = localStorage.getItem(value);
            if (obj !== null) {
                let token = Object.entries(JSON.parse(obj)).find(([key, value]) => {
                    if (key == 'secret') {
                        return value as string
                    }})
                if(token !== null && token !== undefined) {
                    return token[1] as string
                }

            }
        }
    }
    return "";
}