import {Avatar, Box, Group, Menu, Text, UnstyledButton, useMantineTheme} from "@mantine/core";
import {IconChevronLeft, IconChevronRight, IconLogout} from '@tabler/icons-react';
import {useMsal} from "@azure/msal-react";

interface NavBarUserProfileProps {

}

function NavBarUserProfile() {

    const theme = useMantineTheme();
    const { instance, accounts, inProgress } = useMsal();

    const handleLogout = (logoutType:string) => {
        if ((logoutType) === 'redirect') {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/"
            })
        }
    }

    const generateIconInitials = (word: string | undefined) => {
        if (word == undefined) {
            return "U"
        }
        let initials = "";
        word.split(" ").forEach(w => {
            if (/[a-zA-Z]/i.test(w.charAt(0))) {
                initials += w.charAt(0)
            }
        })
        return initials
    }

    return (
        <Box
            sx={{
                paddingTop: theme.spacing.sm,
                borderTop: `1px solid ${
                    theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
                }`,
            }}
        >

            <Menu position='top'>
                <Menu.Target>
                    <UnstyledButton
                        sx={{
                            display: 'block',
                            width: '100%',
                            padding: theme.spacing.xs,
                            borderRadius: theme.radius.sm,
                            color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

                            '&:hover': {
                                backgroundColor:
                                    theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                            },
                        }}
                    >
                        <Group noWrap={true}>
                            <Avatar color="cyan" radius="xl">
                                {generateIconInitials(accounts[0]?.name)}
                            </Avatar>
                            <Box sx={{ flex: 1, overflow: 'hidden' }}>
                                <Text size="sm" weight={500} sx={{textOverflow: 'ellipsis', overflow: 'hidden'}}>
                                    {accounts[0]?.name}
                                </Text>
                                <Text color="dimmed" size="xs" sx={{textOverflow: 'ellipsis', overflow: 'hidden'}}>
                                    {accounts[0]?.username}
                                </Text>
                            </Box>

                            {theme.dir === 'ltr' ? <IconChevronRight size={18} /> : <IconChevronLeft size={18} />}
                        </Group>
                    </UnstyledButton>
                </Menu.Target>

                <Menu.Dropdown>
                    <Menu.Item icon={<IconLogout />} onClick={() => handleLogout('redirect')}>
                        Log Out
                    </Menu.Item>
                </Menu.Dropdown>
            </Menu>

        </Box>
    )
}

export default NavBarUserProfile