import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const InsertProductSelectedProductTypeSlice = createSlice({
    name: "insertProductSelectedProdyctType",
    initialState: "",
    reducers: {
        set: (state, action: PayloadAction<string>) =>
            state = action.payload
    }
})

const {reducer, actions} = InsertProductSelectedProductTypeSlice

export const insertProductSelectedProductTypeActions = actions
export default reducer