import {Button, Flex, Text, TextInput, Tooltip} from "@mantine/core";
import React, {useState} from "react"
import { IconInfoCircle } from '@tabler/icons-react';
import TableLoadingRow, {TableLoadingRowProps} from "../../TableLoadingRow";
import {LoadingState} from "../../../interfaces/LoadingState";

export interface TechnicalDetailsSearchProps {
    buttonText: string;
    tooltip?: string;
    state: LoadingState;
    clicked: (input: string) => void;
}

function TechnicalDetailsSearch(props: TechnicalDetailsSearchProps) {

    const [textInputValue, setTextInputValue] = useState('');

    return (
        <Flex mb="1rem">
            <Flex w={{base: '120px', sm: '240px'}} align={"center"} justify={"column"}>
                <Button
                    w={{base: '80px', sm: '160px'}}
                    color="dark"
                    radius="lg"
                    onClick={() => props.clicked(textInputValue)}
                >
                    {props.buttonText}
                </Button>
                <Flex w={{base: '24px'}} mx='5px'>
                    {props.tooltip &&
                            <Flex align={"center"} justify={"column"}>
                                <Tooltip multiline label={props.tooltip}>
                                        <IconInfoCircle />
                                </Tooltip>
                            </Flex>
                    }
                </Flex>
                <Flex w={{base: '28px'}} mx="15px">
                    <TableLoadingRow loadingState={props.state} />
                </Flex>
            </Flex>
            <TextInput
                w={{base: '100%'}}
                mx='15px'
                value={textInputValue} onChange={(event) => setTextInputValue(event.currentTarget.value)}/>
        </Flex>

    )

}

export default TechnicalDetailsSearch;