import {useDetailsFormContext} from "../../pages/main/InserimentoDati";
import React from "react";
import {ArticleFormDetail} from "../../interfaces/form/ArticleFormDetail";
import {Flex, Select} from "@mantine/core";

export function InputForm(a: ArticleFormDetail): JSX.Element {
    // Get the context provided by the form provider
    const form = useDetailsFormContext();
    return (
        <>
            {a.content.map((section, key) => {
                return <Flex key={key}
                    wrap='wrap'
                    justify='space-start'>
                    {section.map((k,v) => {
                        let Input = k.type;
                        let props: Record<string, any> = {}
                        if (Input === Select) {
                            props.data = k.options
                        }
                        return <Input
                            p='15px'
                            radius={"lg"}
                            key={v}
                            label={k.label}
                            w={{base: '100%', sm: '30%'}}
                            {...form.getInputProps(`productDetails.${a.productType}.${k.formName?k.formName:k.label}`)}
                            {...props}/>
                    })}
                </Flex>
            })}
        </>
    )
}