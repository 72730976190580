import {createApi} from "@reduxjs/toolkit/query/react"
import {retry} from "@reduxjs/toolkit/query"
// import { login } from "./userReducer"
import {BaseQueryWithReAuth} from "../configuration/authInterceptor";

export const serverApi = createApi({
    reducerPath: "articles",
    baseQuery: retry(BaseQueryWithReAuth, {maxRetries: 2}),
    endpoints: (builder) => ({
        authenticate: builder.mutation({
            query(data) {
                return {
                    url: "/account/authenticate",
                    method: "POST",
                    body: data
                }
            }
        })
    })
})

export const { useAuthenticateMutation } = serverApi