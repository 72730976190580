
//TODO Generalize common strings
export const classiEnergetiche: string[] = ['A', 'B', 'C', 'D', 'E', 'F', 'G']

/* -------------- */
/* -- LAVAGGIO -- */
/* -------------- */
export const LavatricePosizionamento: string[] = [
    'Da Incasso',
    'Libera Installazione'
]

export const LavatriceTipologia: string[] = [
    'Caricamento Frontale',
    "Caricamento dall'alto",
]

export const AsciugatricePosizionamento: string[] = [
    'Da Incasso',
    'Libera Installazione'
]

export const LavasciugaPosizionamento: string[] = [
    'Da Incasso',
    'Libera Installazione'
]

export const LavastovigliePosizionamento: string[] = [
    'Da Incasso',
    'Libera Installazione',
    'Scomparsa Parziale',
    'Scomparsa Totale',
    'Sottopiano',
    'Superficie Piana'
]

/* ------------------- */
/* -- CONSERVAZIONE -- */
/* ------------------- */
export const FrigoriferoTipologia: string[] = [
    'Combinato',
    'Da Tavolo',
    'Doppia Porta',
    'Mini',
    'Monoporta',
    'Portatile',
    'Side By Side',
    'Sottopiano'
]

export const FrigoriferoPosizionamento: string[] = [
    'Da Incasso',
    'Libera Installazione',
    'Portatile',
    'Sottopiano'
]

export const CongelatoreTipologia: string[] = [
    'A pozzo',
    'Verticale'
]

export const CongelatorePosizionamento: string [] = [
    'Da Incasso',
    'Libera Installazione',
    'Sottopiano'
]

/* ----------------------- */
/* -- COMPONENTI CUCINA -- */
/* ----------------------- */
export const PianoCotturaTipologia: string[] = [
    'Elettrico',
    'A Induzione',
    'Gas',
    'Ceramica',
    'Gas + Induzione',
    'Combi'
]

export const PianoCotturaPosizionamento: string[] = [
    'Libera Installazione',
    'Da Incasso',
    'Superficie Piana'
]

export const PianoCotturaMateriale: string[] = [
    'Cristallo Bianco',
    'Vetro Temperato',
    'Cristallo Nero',
    'Acciaio Inossidabile',
    'Metaltek',
    'Ceramica',
    'Vetro-ceramica',
    'Smaltato',
    'Ghisa',
    'Vetro'
]

export const FornoTipologia: string[] = [
    'Elettrico',
    'A gas',
    'A vapore',
    'Sia Elettrico che a Gas'
]

export const FornoPosizionamento: string[] = [
    'Da Incasso',
    'Libera Installazione'
]

export const CappeTipologia: string[] = [
    'Cappa Aspirante ad Isola',
    'Integrato a Soffitto',
    'Cappa ad Angolo',
    'Cappa aspirante a Parete',
    'Semintegrato (semincassato)',
    'Downdraft',
    'Cappa sottopensile',
    'A soffitto',
    'Cappa aspirante ad Incasso'
]

export const LavelloTipologia: string[] = [
    'Ad incasso',
    'Sottotop',
    'Sopratop',
    'Montato su Piano',
    'Incasso/Sottotop',
    'Filotop',
    'Sottopiano'
]

/* ---------------------------- */
/* -- ALTRI ELETTRODOMESTICI -- */
/* ---------------------------- */
export const MicroondePosizionamento: string[] = [
    'Da Incasso',
    'Libera Installazione',
    'Superificie Piana'
]