import {persistReducer, persistStore} from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import {configureStore} from '@reduxjs/toolkit'
import {serverApi} from "../services/RestService";
import rootReducer from './Reducers';

const persistConfig = {
    key: 'root',
    //whitelist: ['navigation'] // only navigation will be persisted
    //blacklist: ['navigation'] // navigation will not be persisted
    storage,
    blacklist: [
        serverApi.reducerPath,
    ]
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({serializableCheck: false}).concat(serverApi.middleware),
    devTools: true,
})

export type Store = ReturnType<typeof store.getState>

export const persistor = persistStore(store)
