import {Group, Text, ThemeIcon, UnstyledButton} from "@mantine/core";
import {useNavigate} from "react-router";
import {useDispatch} from "react-redux";
import {mobileNavBarActions} from "../redux/slices/MobileNavBarSlice";

interface NavBarLinkProps {
    icon: JSX.Element,
    color: string
    label: string
    url: string
}

function NavBarLink({icon, color, label, url}: NavBarLinkProps) {
    const navigate = useNavigate();
    const dispatch = useDispatch()

    return (
        <UnstyledButton
            sx={(theme) => ({
                display: 'block',
                width: '100%',
                padding: theme.spacing.xs,
                borderRadius: theme.radius.sm,
                color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

                '&:hover': {
                    backgroundColor:
                        theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                },
            })}

            onClick={() =>{
                navigate(url)
                dispatch(mobileNavBarActions.switch())
            } }
        >
            <Group>
                <ThemeIcon color={color} variant="light">
                    {icon}
                </ThemeIcon>

                <Text size="sm">{label}</Text>
            </Group>
        </UnstyledButton>
    )
}

export default NavBarLink;