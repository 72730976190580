import {Loader, ThemeIcon} from "@mantine/core";
import {IconCheck, IconX} from "@tabler/icons-react";
import {LoadingState} from "../interfaces/LoadingState";

export interface TableLoadingRowProps {
    loadingState: LoadingState
}

function TableLoadingRow({loadingState}: TableLoadingRowProps ) {

    if (loadingState == undefined) {
        return <></>
    }

    if (loadingState.loading) {
        return <Loader size={'sm'}/>
    }

    if (loadingState.completed) {
        return (
            <ThemeIcon variant={'outline'} radius={'lg'} color={'teal'}>
                <IconCheck />
            </ThemeIcon>
        )
    }

    if (loadingState.error) {
        return (
            <ThemeIcon variant={'outline'} radius={'lg'} color={'red'}>
                <IconX />
            </ThemeIcon>
        )
    }

    return <></>


}

export default TableLoadingRow;