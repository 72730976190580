import {Burger, Flex, Header, Image, MediaQuery} from "@mantine/core";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Store} from "../redux/Store";
import {mobileNavBarActions} from "../redux/slices/MobileNavBarSlice";

// TODO: Implement Theme

function HeaderComponent() {


    const opened = useSelector((state: Store) => state.mobileNavBar)
    const dispatch = useDispatch()
    return (
        <Header height={{base: 50, md: 70}} p="md">
            <Flex align={"center"} styles={{height: '100%'}}>
                <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
                    <Burger
                        opened={opened}
                        onClick={() => dispatch(mobileNavBarActions.switch())}
                        size="sm"
                        //color={theme.colors.gray[6]}
                        mr="md"
                    />
                </MediaQuery>
                <Image width={270} src={"/assets/Logo Outletstocks.png"} />
            </Flex>
        </Header>
    )
}

export default HeaderComponent;