import React, {useEffect, useState} from 'react';
import {Button, Flex} from '@mantine/core';
import NavBarComponent from "../../components/NavBar";
import HeaderComponent from "../../components/Header";
import Shell from "../../components/Shell";
import TableFolders from "../../components/TableFolders";
import {IconFileUpload} from "@tabler/icons-react";
import {ImageColumns} from "../../interfaces/ImageTablePage";
import {useLazyGetAllCsvFilesQuery, useUploadCsvFilesMutation} from "../../services/ArticleService";
import {TableCSVRow} from "../../interfaces/TableCSVRow";

const CaricamentoCSV = () =>  {

    const [getAllCsvFiles, isLoadingGetAllCsvFiles, isErrorGetAllCsvFiles] = useLazyGetAllCsvFilesQuery();
    const [uploadFiles, isLoading] = useUploadCsvFilesMutation();

    const [files, setFiles] = useState<TableCSVRow[]>([])
    const [selectedFiles, setSelectedFiles] = useState<TableCSVRow[]>([]);


    useEffect(() => {
        getAllCsvFiles().then((r) => {
            if(r.data == undefined) {
            }
            else {
                let mappedData: TableCSVRow[] = []
                r.data.forEach(d => mappedData.push({...d}))
                setFiles(mappedData)
            }

        })
    }, [])

    const handleClick = () => {
        if(selectedFiles.length === 0) {
            console.log("You have selected no folder")
            return
        }


        selectedFiles.forEach(folder => {
            folder.loading = {loading: true, completed: undefined, error: undefined}
            let i = files.findIndex(f => f.name === folder.name)
            files[i] = folder
        })
        setFiles([...files])

        selectedFiles.forEach(file => {
            uploadFiles({files: [file.name]})
                .unwrap()
                .then(resp => {
                    file.loading = {loading: undefined, completed: true, error: undefined}
                    let i = files.findIndex(f => f.name === file.name)
                    files[i] = file
                    setFiles([...files])
                })
                .catch(err => {
                    console.log(err)
                    file.loading = {loading: undefined, completed: undefined, error: true}
                    let i = files.findIndex(f => f.name === file.name)
                    files[i] = file
                    setFiles([...files])
                })
        })
    }

    return (
        <Shell
            navbar={<NavBarComponent />}
            header={<HeaderComponent/>}
            body={
                <>
                    <Flex style={{paddingBottom: '32px', paddingTop: '16px'}}>
                        <Button
                            radius={'lg'}
                            leftIcon={<IconFileUpload/>}
                            onClick={handleClick}
                            sx={{backgroundColor: '#aed581', color: 'black', '&:hover': {backgroundColor: '#a1cf6d'}}}>
                            Carica CSV
                        </Button>
                    </Flex>
                    <TableFolders
                        columns={ImageColumns}
                        rows={files}
                        loading={isLoadingGetAllCsvFiles.isFetching}
                        selected={selectedFiles}
                        setSelected={setSelectedFiles} />
                </>
            }
        />
    )
}

export default CaricamentoCSV;